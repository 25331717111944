// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-aktivitaeten-js": () => import("./../../../src/pages/aktivitaeten.js" /* webpackChunkName: "component---src-pages-aktivitaeten-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-corona-js": () => import("./../../../src/pages/corona.js" /* webpackChunkName: "component---src-pages-corona-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-reisefinder-js": () => import("./../../../src/pages/reisefinder.js" /* webpackChunkName: "component---src-pages-reisefinder-js" */),
  "component---src-pages-reisen-js": () => import("./../../../src/pages/reisen.js" /* webpackChunkName: "component---src-pages-reisen-js" */),
  "component---src-pages-ziele-js": () => import("./../../../src/pages/ziele.js" /* webpackChunkName: "component---src-pages-ziele-js" */),
  "component---src-templates-activity-js": () => import("./../../../src/templates/activity.js" /* webpackChunkName: "component---src-templates-activity-js" */),
  "component---src-templates-corona-js": () => import("./../../../src/templates/corona.js" /* webpackChunkName: "component---src-templates-corona-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-travel-js": () => import("./../../../src/templates/travel.js" /* webpackChunkName: "component---src-templates-travel-js" */)
}

