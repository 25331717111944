import './src/styles/index.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/dist/base.min.css';
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
